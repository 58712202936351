/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

.sb {
    font-weight: 600 !important;
}

code {
  color: #E01A76;
}

.good {
    color: green;
}

.bad {
    color: red;
}

.semibold {
    color: black;
    font-weight: 500;
    margin-left: 5px;
    margin-top: .25em;
    margin-bottom: .5em;
}

.bold {
    color: black;
    font-weight: 500;
    font-size: 22px;
    margin-left: 5px;
    margin-top: .25em;
    margin-bottom: .5em;
}

.unselectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.alerttriage-unselectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.aconboarding-unselectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.furnanceonboarding-unselectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

hr {
    margin-top: .5em;
    margin-bottom: .5em;
}

p {
    margin-top: .5em;
    margin-bottom: .5em;
}

th {
    font-size: 80%;
    font-weight: bold;
}

td {
    font-size: 80%;
    font-weight: 400;
    vertical-align: middle;
}

.extended-icon {
    display: flex;
    align-items: center;
    color: #0033a0;
}
.extended-left {
    justify-content: right;
}
.extended-right {
    justify-content: left;
}
.extended-color {
    color: #0033a0;
}

.btn-icon {
    margin: 0 0 5px 5px;
    padding: 0;
}

.btn-red {
    color: red !important; 
    border-color: red !important;
}

.btn-close {
    border: 0;
    background: #fff;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.btn-wide {
    width: 250px;
    background-color: #0033a0;
    color: white;
    justify-self: center;
}

.btn-main {
    display: inline-block;
    padding: .25em .4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25rem;
    transition: color .15s;
    color: white;
    background-color: #0033a0;
    margin-right: 2px;
}

.form-control {
    border: 1px solid #ebeef0;
}

.form-control-text-runoff {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    white-space: nowrap;
    overflow: scroll;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ebeef0;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-control-link {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    white-space: nowrap;
    overflow: scroll;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

input[type="text"] {
    border-radius: .3em;
}

input[type="text"]:disabled {
    background-color: white;
}

.gatewaybtn-right {
    float: right;
    margin-right: 15px !important;
}

.primary-btn {
    color: white;
    background-color: #0033a0;
    text-align: center;
    border: 2px solid #0033a0;
    font-weight: 500;
    font-size: 90%;
    display: inline-block;
    white-space: nowrap;
    vertical-align: baseline;
    margin-right: 5px;
}

.primary-btn:disabled {
    background-color: lightgrey;
    color: grey;
    border-color: grey;
}

.validation-lbl {
    text-align: center;
    font-weight: 500;
    font-size: 90%;
    display: inline-block;
    white-space: nowrap;
    vertical-align: baseline;
    margin-left: 5px;
    padding: .25em .4em;
    line-height: 1;
    margin-right: 2px;
}

.secondary-btn {
    color: #0033a0;
    background-color: white;
    text-align: center;
    border: 2px solid #0033a0;
    font-weight: 500;
    font-size: 90%;
    display: inline-block;
    white-space: nowrap;
    vertical-align: baseline;
    margin-right: 5px;
}

.secondary-btn:disabled {
    background-color: lightgrey;
    color: grey;
    border-color: grey;
}

.red-btn {
    color: white;
    background-color: red;
    text-align: center;
    border: 2px solid red !important;
    font-weight: 500;
    font-size: 90%;
    display: inline-block;
    white-space: nowrap;
    vertical-align: baseline;
    margin-right: 5px;
}

.red-btn:disabled {
    background-color: lightgrey;
    color: grey;
    border-color: grey !important;
}

.graph-btn:disabled {
    color: transparent !important;
    border: 2px solid transparent !important;
    background-color: transparent !important;
}
.graph-btn-triage {
    width: 140px;
    margin-bottom: 2px;
}
.graph-triage-container {
    margin-left: 15px !important;
    margin-top: 20px !important;
    width: 150px !important;
}
.graph-radio-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
}

.tertiary-btn {
    color: #0033a0;
    background-color: transparent;
    text-align: center;
    font-weight: 500;
    font-size: 90%;
    display: inline-block;
    white-space: nowrap;
    vertical-align: baseline;
    padding: .25em .4em;
    line-height: 1;
    margin: 2px;
    border: none;
}

.btn-small {
    padding: .25em .4em;
    line-height: 1;
    margin-right: 2px;
    border-radius: .4rem;
    border: 1px solid #0033a0;
}

.btn-medium {
    padding: 5px 10px;
    margin-right: 5px;
    border-radius: 1rem;
    border: 1px solid #0033a0;
}

.btn-large {
    padding: 7px;
    margin-right: 5px;
    width: 75px;
    border-radius: .75rem;
}

.btn-center {
    width: 200px;
    border-radius: .75rem;
}

.center {
    text-align: center;
}

.btn-detail {
    line-height: 1;
    border-radius: .15rem;
    border: 1px solid black;
    height: 25px;
    margin-bottom: .75em;
    text-align: center;
    background-color: white;
    color: black;
    font-weight: 500;
    font-size: 90%;
    vertical-align: baseline;
    padding: .25em .4em;
}

.btn-right {
    display: flex;
    justify-content: space-around;
    position: absolute;
    float: right;
    margin-right: 5%;
    right: 0;
}

.btn-left {
    display: flex;
    justify-content: space-around;
    position: absolute;
    float: left;
    margin-left: 5%;
}

.btn-view-all {
    width: calc(100% - 30px);
    margin-left: 15px;
    margin-bottom: 5px;
}

.btn-retrain {
    color: red;
    border: 1px solid red;
    margin-left: 10px;
}

.dot {
    height: 5px;
    width: 5px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    margin: 0.5px;
}

/* Comments CSS here */

.flex-spread {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
}
.flex-end {
    display: flex !important;
    justify-content: flex-end;
    align-items: center;
    flex: 1;
}

.flex-spread-normal {
    align-items: normal;
    justify-content: normal;
}

.chat-date-header {
    border-top: 1px solid #eee;
    margin-top: 8px;
}

.submit-btn {
    color: white;
    background-color: #0033a0;
    text-align: center;
    border: 2px solid #0033a0;
    display: inline-block;
    white-space: nowrap;
    vertical-align: top;
    margin-right: 5px;
    font-size: xx-large;
}

.answer-btn {
    color: white;
    background-color: #0033a0;
    text-align: center;
    border: 2px solid #0033a0;
    display: inline-block;
    white-space: normal;
    vertical-align: top;
    margin-right: 5px;
    font-size: medium;
}
/* Comments End */

.image-profile {
    display: flex;
    border-radius: 5px;
    background-color: #324158;
    width: 32px;
    height: 32px;
    font-size: 14px;
    color: white;
    letter-spacing: 1px;
    align-items: center;
    justify-content: center;
}

.status-msg {
    margin-top: 10px;
    margin-left: 15px;
    margin-bottom: 10px;
}

.pageTitle {
    padding: 10px;
    border-bottom: 1px solid #ebeef0;
    margin-bottom: 0px !important;
}
.pageTitle-border-none{
    border-bottom: none; 
    padding: 20px 0 0 30px;
}

.sectionTitle {
    padding: 15px 15px 10px 15px;
    border-bottom: 1px solid #ebeef0;
    margin-left: 0px;
}

.detailTitle {
    color: #5b7083;
    margin-left: 5px;
    margin-bottom: 5px;
}

.sideTitle {
    color: #5b7083;
    width: 205px;
    margin-left: 10px;
    margin-right: 10px;
    padding-top: 10px;
    padding-bottom: 5px;
    border-top: 2px solid #ebeef0;
    font-size: .85em;
}

.lastItem {
    margin-bottom: .5rem;
    padding-left: 5px;
}

.lastButton {
    margin-bottom: .5rem;
    margin-right: 5px;
}

.infoPage {
    padding-bottom: 5%;
    padding-top: 60px;
    margin-left: 225px;
    background-color: #ffffff; 
    height: 100%;
    overflow-y: scroll;
}

.registerUserInfoPage {
    margin-left: 15px;
    margin-top: 62px;
    padding-top: 25px;
    margin-right: 15px;
}

.landingPage {
    padding-top: 110px;
    background-color: #ffffff; 
    height: 100%;
    width: 100%;
    position: fixed;
    text-align: center;
    z-index: -1;
}

.version-btn {
    background: none;
    border: none;
    color: #0033a0;
}

.passwordPage {
    padding-top: 40px;
    background-color: #f7f9fa;
    height: 100%;
    width: 100%;
    position: fixed;
    text-align: center;
    overflow: scroll;
}

.password-container {
    padding: 35px 35px 15px 35px;
    text-align: center;
    background: white;
    width: 450px;
    margin: 5px;
    border: 1px solid #ebeef0;
    overflow: scroll;
}

.passwordTitle {

}

.passwordBody {
    color: #5b7083;
    padding: 10px 10px 5px 10px;
}

.navbar-brand {
    background-size: 24px;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.app-bar {
    color: black;
    background-color: #153755 !important;
    border-bottom: 1px solid #ebeef0;
    height: 60px;
    padding: 5px;
}
.app-bar-height {
    height: 45px !important;
    min-height: 45px !important;
}

.app-bar-icon {
    color: #ffffff !important;
    height: 33px !important; 
    width: 33px !important; 
}

.app-fave-menu {
    color: inherit;
    margin-right: 25px;
}
.app-fave-header {
    line-height: 2;
    padding-top: 5px;
}

.app-bar-avatar {
    background-color: #ffffff;
    color: #153755;
    height: 35px;
    width: 35px; 
    font-size: 18px;
}

.tooltip {
    margin-top: -5px;
    padding-top: -5px;
    background-color: #f7f9fa;
}

.img-responsive {
    display: inline;
    vertical-align: sub;
    height: 30px;
    margin-top: 5px;
}
.sidebar-select-message {
    padding-left: 5px !important;
}
.sidebar-select {
    padding: 0 10px;
}

.sidebar-search-select {
    height: 30px;
    background-color: transparent;
    border: 0;
    color: #212529;
    padding-right: 12px;
}

.sidebar-select-2 {
    margin: 10px 0;
}

.sidebar-search-container {
    margin: 0 10px;
}

.sidebar-expand {
    height: 18px;
    width: 16px; 
    padding-bottom: 2px;
}
.sidebar-expand-btn {
    background-color: transparent;
    height: 26px;
    border: 0;
    vertical-align: top;
    float: right;
}
.sidebar-icon {
    margin: 0 10px;
    font-size: 20px;
}
.sidebar-icon-version {
    margin: 20px 10px;
    font-size: 20px;
}

.arrow-icon {
    height: 16px;
    width: 15px;
    padding-bottom: 2px;
}
.column-icon {
    height: 15px;
    padding-left: 3px;
}

.bottom-border {
    border-bottom: 2px solid #ebeef0;
}

.sidebar-section {
    height: 40px;
    border-top: 2px solid #ebeef0;
    padding-top: 15px;
    margin-bottom: 15px;
}
.sidebar-container {
    display: inline-flex;
    width: 100%;
    height: 35px;
    border-top: none;
}
.sidebar-item {
    margin: 0;
    display: inline-block;
    width: 100%;
    pointer-events: none;
}
.sidebar-section-item {
    margin: 0;
    display: inline-block;
    width: calc(100% - 40px);
    pointer-events: none;
}
.sidebar-version-item {
    padding-bottom: 10px;
    border-top: 2px solid #ebeef0;
    display: flex;
}
.sidebar-child-item {
    padding-left: 10px;
    padding-top: 3px;
    padding-bottom: 2px;
    display: inline;
    pointer-events: none;
}

.select-container-2 {
    margin-top: 10px;
    margin-bottom: 15px;
}

.select-container-3 {
    margin-top: 10px;
    margin-bottom: 15px;
    margin-left: 5px;
}

.select-message{
    padding-left: 15px;
}

.selectpicker {
    margin-left: 10px;
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: none;
    padding-left: 13px;
}

.asset-select-picker {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: none;
}
.asset-select-datepicker {
    max-width: 274px;
    text-align: center;
    padding-right: 18px;
}

.asset-datepicker {
    min-width: 200px !important;
    width: 15% !important;
    text-align: center;
}

.asset-run-select {
    max-width: 228px;
    padding-left: 10px;
    padding-right: 18px;
}

.selectpicker-modal {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: none;
    padding-left: 13px;
}

select {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: none;
    background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='black'><polygon points='15,25 85,25 50,75'/></svg>") no-repeat;
    background-size: 12px;
    background-position: calc(100% - 5px) center;
    background-position-y: center;
    background-repeat: no-repeat;
    background-color: white;
    border-radius: .3em;
    border: 1px solid #ebeef0;
}

.onTop {
    position: fixed;
    top: 0;
    z-index: 3;
    width: 100%;
}

.txt-wide{
    width: 500px;
    border-radius: .3em;
}

.txt-detail {
    margin-bottom: .75em;
    width: 95%;
    height: 25px;
    border: 1px solid #ebeef0; /*grey;*/
    border-radius: .3em;
    padding-left: 5px;
}

.edited-prop {
    font-weight: 500;
    border-color: #0033a0;
}

.edited-publish {
    font-weight: 500;
    background-color: #dee9ff;
}

.disabled-prop {
    background-color: #f2f2f2 !important;
    color: dimgray;
} 

.tag {
    text-align: left;
    background: #ebeef0;
    padding-left: 3px;
    margin-right: 5px;
    border-radius: 0.4em;
    border: 1px solid grey;
    overflow: auto;
}

.centered {
    justify-content: center;
    display: flex;
    margin-bottom: .7em;
}

.white-container {
    padding: 0px;
    text-align: left;
    background: white;
    width: 100%;
    margin-right: 5px;
    border-right: 1px solid #ebeef0;
    border-bottom: 1px solid #ebeef0;
    overflow: auto;
}

.grey-container {
    padding: 0px;
    text-align: left;
    background: #f7f9fa;
    width: 94%;
    margin-right: 3%;
    margin-left: 3%;
    margin-top: 10px;
    border-radius: 1.25em;
    border: 1px solid #ebeef0;
    overflow: auto;
}
.analyze-container {
    margin-left: 15px !important;
    margin-top: 20px !important;
    width: 200px !important;
}

.table-container {
    margin-left: 15px !important;
    margin-right: 15px !important;
}

.table-container-2 {
    margin-left: 10px !important;
    margin-right: 10px !important;
}

.radio-container {
    margin: 10px 10px 10px 15px;
}

.input-container {
    margin-left: 15px;
    margin-right: 15px;
}

.tableSelected {
    background-color: #0033a0 !important;
    color: white;
}

option:checked {
    color: red;
}

label {
    margin-top: 8px;
    margin-bottom: 2px !important;
    padding: 0px;
}

.formLbl {
    margin-top: 8px;
    margin-bottom: 2px !important;
    padding-left: 5px;
    color: black;
}

.col {
    display: table-cell;
}

/*grid spacing*/
.sr-container {
    display: grid;
    grid-gap: 15px;
    grid-template-columns: 75% 25%;
    grid-template-rows: auto auto;
    height: 95vh;
}

.sr1 {
    grid-row-start: 1;
    grid-row-end: 3;
}

.react-datepicker-wrapper {
    display: unset !important;
    width: inherit;
    /*text-align: center;*/
}

.react-tabs__tab-list {
    margin-bottom: 0px !important;
    margin-top: 5px;
}

.hidden {
    visibility: hidden;
}

.map-container {
    height: 500px;
}

.mapboxgl-marker {
    opacity: 0.65;
}

.marker-green {
    height: 14px;
    width: 14px;
    border-radius: 7px;
    background-color: rgba(0, 255, 0, 0.65);
    background-color: rgb(0, 255, 0);
    color: rgba(0, 255, 0, 0.65);
    color: rgb(0, 255, 0);
}

.marker-yellow {
    height: 14px;
    width: 14px;
    border-radius: 7px;
    background-color: rgba(255, 255, 0, 0.65);
    background-color: rgb(255, 255, 0);
    color: rgba(255, 255, 0, 0.65);
    color: rgb(255, 255, 0);
}

.marker-red {
    height: 14px;
    width: 14px;
    border-radius: 7px;
    background-color: rgba(255, 0, 0, 0.65);
    background-color: rgb(255, 0, 0);
    color: rgba(255, 0, 0, 0.65);
    color: rgb(255, 0, 0);
}

.react-contextmenu {
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: .25rem;
    color: #373a3c;
    font-size: 12px;
    margin: 2px 0 0;
    min-width: 160px;
    outline: none;
    opacity: 0;
    padding: 2px 3px;
    pointer-events: none;
    text-align: left;
    transition: opacity 250ms ease !important;
}

.react-contextmenu.react-contextmenu--visible {
    opacity: 1;
    pointer-events: auto;
    z-index: 9999;
}

.react-contextmenu-item {
    background: 0 0;
    border: 0;
    color: #373a3c;
    cursor: pointer;
    font-weight: 400;
    line-height: 1.5;
    padding: 3px 20px;
    text-align: inherit;
    white-space: nowrap;
}

.react-contextmenu-item.react-contextmenu-item--active,
.react-contextmenu-item.react-contextmenu-item--selected {
    color: #fff;
    background-color: #20a0ff;
    border-color: #20a0ff;
    text-decoration: none;
}

.react-contextmenu-item.react-contextmenu-item--disabled,
.react-contextmenu-item.react-contextmenu-item--disabled:hover {
    background-color: transparent;
    border-color: rgba(0,0,0,.15);
    color: #878a8c;
}
.display-inline{
    display: inline;
}
.display-grid{
    display: grid;
}
.card-background{
    background-color: #e6e6e6 !important;
}
.card-content{ 
    display: flex; 
    font-size: 2.25rem; 
    justify-content: center; 
    align-items: center;
}
.card-row {
    text-align: center;
    margin: 12px 0px;
}

.dot-flashing {
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #1b6ec2;
    color: #1b6ec2;
    animation: dotFlashing 1s infinite linear alternate;
    animation-delay: .5s;
}

.dot-flashing::before, .dot-flashing::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
}

    .dot-flashing::before {
        left: -15px;
        width: 10px;
        height: 10px;
        border-radius: 5px;
        background-color: #1b6ec2;
        color: #1b6ec2;
        animation: dotFlashing 1s infinite alternate;
        animation-delay: 0s;
    }

    .dot-flashing::after {
        left: 15px;
        width: 10px;
        height: 10px;
        border-radius: 5px;
        background-color: #1b6ec2;
        color: #1b6ec2;
        animation: dotFlashing 1s infinite alternate;
        animation-delay: 1s;
    }

@keyframes dotFlashing {
  0% {
    background-color: #1b6ec2;
  }
    50%, 100% {
        background-color: #D4E2F0;
    }
}

.datepicker {
    min-width: 200px;
    text-align: center;
}

.dashboard-title {
    border-bottom: none;
    padding: 20px 0 0 30px;
}

.dashboard-number-2 {
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1;
}

.dashboard-number {
    font-size: 1.75rem;
    font-weight: 500;
    line-height: 1;
}
.db-num2 {
    padding-top: 10px;
}

.db-num3 {
    padding-top: 6px;
    padding-left: 2px;
    font-size: 2rem !important;
}

.dashboard-val:hover {
    transform: scale(1.15);
}

.dashboard-icon {
    height: 50px !important;
    width: 50px !important;
    padding: 10px;
    border-radius: 25px;
    margin-right: 12px;
}
.dashboard-icon-sm {
    height: 40px !important;
    width: 40px !important;
    padding: 8px;
    border-radius: 20px;
    margin-right: 5px;
    margin-left: 10px;
}

.db-blue {
    color: #0033a0;
}
.db-blue-bg {
    background-color: #dcedfe;
}
.db-blue-bg2 {
    background-color: #0033a0 !important;
}
.db-blue-reverse {
    color: #0033a0;
    border: 1px solid #0033a0;
}

.db-red {
    color: #ff7555;
    background-color: #f6d7d0;
}
.db-red-bg {
    background-color: #f6d7d0;
}
.db-red-bg2 {
    background-color: #ff7555 !important;
}

.db-orange {
    color: #ff9900;
    background-color: #fde9cc;
}
.db-orange-bg {
    background-color: #fde9cc;
}
.db-orange-bg2 {
    background-color: #ff9900 !important;
}

.db-teal {
    color: #3ed3c2;
    background-color: #d3f5f1;
}
.db-teal-bg2 {
    background-color: #3ed3c2 !important;
}

.db-purple {
    color: #7d8ee2;
    background-color: #dee4ff;
}
.db-purple-bg {
    background-color: #dee4ff;
}
.db-purple-bg2 {
    background-color: #7d8ee2 !important;
}
.db-tan-bg2 {
    background-color: #98712d !important;
}
.db-salmon-bg2 {
    background-color: #f06b66 !important;
}
.db-maroon-bg2 {
    background-color: #cb4a49 !important;
}
.db-sky-blue-bg2 {
    background-color: #1ea3c1 !important;
}
.db-green-bg2 {
    background-color: #22a877 !important;
}
.db-pink-bg2 {
    background-color: #d66ec7 !important;
}
.db-gray {
    background-color: #a3b9c8 !important;
}
.db-gray-bg2 {
    background-color: #63737e !important;
}
.db-violet-bg2 {
    background-color: #8d377c !important;
}

.show-all-btn {
    padding: 25px 25px 25px 30px;
    margin-right: 0;
}
.btn-150 {
    width: 150px;
}
.btn-25 {
    width: 25px;
}

.loading-btn {
    background-color: lightGrey;
    color: grey;
    border-color: grey;
}

.action-btn {
    border-radius: 0.75rem;
    padding: 6px 10px;
    width: 50px;
}

.alerts-tooltip {
    margin-top: -5px; 
    padding-top: -5px; 
    background-color: #f7f9fa;
}
.alerts-tab {
    width: 100%;
}
.cursor-pointer {
    cursor: pointer;
}

.alerts-top-tabs {
    margin: 20px 30px;
    border: 1px solid #ebeef0;
    border-radius: .1rem;
    box-shadow: inset 0 -1px 0 rgba(100,121,143,0.122);
}
.alerts-top-wrapper-0-30 {
    margin: 0 30px;
    background-color: #f7f9fa;
    padding: 10px 0;
}
.alerts-top-wrapper {
    margin: 20px 30px;
    background-color: #f7f9fa;
    padding: 10px 0;
}

.alerts-tab-wrapper {
    justify-content: space-evenly;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.6);
    align-items: center;
}
.alerts-tab-wrapper-grey {
    margin: 0 30px;
    background-color: #f7f9fa;
    padding: 10px 0;
    overflow: visible;
}

.alerts-tab-options {
    height: 24px;
    display: flex;
    align-items: center;
}
.alerts-tab-select {
    min-width: 150px;
    margin-right: 10px;
}
.alerts-tab-select-wide {
    min-width: 200px;
}
.alerts-tab-btn {
    min-width: 50px;
    height: 38px;
    border-radius: .25rem; 
    padding: 0 10px;
    margin-left: 12px;
}

.alerts-indicator {
    height: 3px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    margin: 0px 8px;
    background-color: transparent;
}

.alerts-tab-item {
    display: flex;
    align-items: center;
    min-height: 56px;
    min-width: 200px;
    flex: 1;
    padding: 6px 16px;
    color: rgba(0, 0, 0, 0.6);
    justify-content: center;
}
.alerts-tab-item:hover {
    background-color: rgba(32, 33, 36, 0.059);
}

.alerts-tab-icon {
    margin-bottom: 0;
    margin-right: 16px;
    font-size: 20px;
}
.alerts-info-icon {
    color: #0033a0;
    height: 22px; 
    width: 22px;
}
.alerts-severity-icon {
    border-radius: 12px;
    padding: 3px;
}

.alerts-tab-label {
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.6);
}

.alerts-badge {
    color: white;
    border-radius: 2px;
    font-size: 0.75rem;
    line-height: 16px;
    padding: 1px 4px;
    letter-spacing: 0.3px;
    margin-left: 8px;
    white-space: nowrap;
    display: inline-block;
}
.alerts-wrapper {
    height: 24px;
    display: inline; 
    align-items: center;
    justify-content: space-between;
}

.graph-tabs {
    margin-left: 10px;
    border-bottom: 1px solid #aaa;
}
.graph-assets {
    margin-top: 10px;
    margin-right: 15px;
}
.graph-triage {
    margin-top: 15px;
    margin-right: 25px;
}
.graph-assets-noData {
    margin-top: 10px;
    margin-left: 20px;
    margin-right: 25px;
}

.mailjet-email-status {
    color: white;
    border-radius: 10px
}

main {
    margin: auto;
    max-width: 960px;
}

.alerts-table-item {
    display: flex;
    align-items: center;
    min-height: 30px;
    min-width: 200px;
    flex: 1;
    padding: 5px 10px;
    color: rgba(0, 0, 0, 0.6);
    justify-content: flex-start;
}

tr.alerts-table-row > th {
    padding: 10px 5px;
    color: rgba(0, 0, 0, 0.6);
    font-weight: 500;
}
tr.alerts-table-row > td {
    padding: 16px 6px;
    border-top: 1px solid #ebeef0;
    border-bottom: 1px solid #ebeef0;
    margin: 5px 0px;
}

tr.alerts-table-row > td > .inner {
    margin: 10px;
    overflow: hidden;
}

.table-hover > thead > tr.no-hover:hover > th,
.table-hover > tbody > tr.no-hover:hover > td,
.table-hover > tbody > tr.no-hover:hover > th {
    background-color: white !important;
}

tr.cancel-table > th {
    padding: 10px 0px 10px 10px !important;
}

tr.cancel-table > td {
    padding: 10px 0px 10px 10px !important;
}

tr.device-table-row > th {
    padding: 1px 1px;
    color: rgba(0, 0, 0, 0.6);
    font-weight: 500;
    max-width: 100%;
    white-space: nowrap;
}

tr.device-table-row > td {
    padding: 1px 1px;
    border-top: 1px solid #ebeef0;
    border-bottom: 1px solid #ebeef0;
    margin: 1px 1px;
    max-width:100%;
    white-space:nowrap;
}

tr.device-table-row > td > .inner {
    margin: 1px;
    overflow: hidden;
}
.alerts-td-left {
    text-align: center;
    border-left: 1px solid #ebeef0;
    padding: 16px 10px 16px 6px;
}
.alerts-td-right {
    text-align: center;
    border-right: 1px solid #ebeef0;
    padding: 16px 6px 16px 10px;
}

.alerts-table-selected {
    background-color: #dcedfe !important;
    border-color: #dcedfe !important;
}
tr.alerts-table-selected {
    box-shadow: #0033a029 1px 0px 6px;
}
tr.alerts-table-selected > td {
    border-top: 1px solid #dcedfe;
    border-bottom: 1px solid #dcedfe;
    border-color: #dcedfe !important;
}

tr.expanded > td {
    padding: 20px;
    border: 1px solid #dcedfe;
    color: rgba(0, 0, 0, 0.6);
    box-shadow: #0033a029 1px 1px 6px;
}

input.selectpicker {
    border-radius: 4px;
    position: relative;
    background-color: white;
    border: 1px solid #ebeef0;
    font-size: 14px;
    padding: 10px 26px 10px 12px;
}

.install-check-container {
    padding: 40px 40px
}

.status-update-container {
    padding: 10px 10px
}

.bottom-right {
    position: relative;
    bottom: 0px;
    right: 0px;
}
.top-25p{
    top: 25%;
}
.top-right {
    position: absolute;
    top: 5px;
    right: 5px;
}
.btn-width-height {
    width: 96px;
    margin-bottom: 5px;
    border-radius: .2em;
    height: 25px;
}
.stClear-btn {
    color: #000000;
    background-color: white;
    text-align: center;
    border: 2px solid #000000;
    font-weight: 500;
    font-size: 90%;
    display: inline-block;
    white-space: nowrap;
    vertical-align: baseline;
    margin-right: 5px;
}
.stClear-container {
   display : inline-block;
   float:right
}
.stHeader-container {
    display: inline-block;
    
}

.demo-wrapper {
    width: 100% !important;
    display: block !important;
    margin-bottom: 25px !important;
    height: 300px !important;
}

.demo-editor {
    height: 250px !important;
    border: 1px solid #ced4da !important;
    padding: 5px !important;
    border-radius: .3em !important;
}

.reactive-dashboard {
    display: flex;
}
.reactive-dashboard-justify {
    height: 24px;
    align-items: center;
    justify-content: space-between;
}

.training-card-grid {
    display: grid;
    gap: 40px;
    grid-template-columns: repeat(auto-fill, minmax(422px, 1fr));
    padding: 30px;
    justify-items: center;
}

.mobile-training-card-grid {
    display: grid;
    gap: 0;
    grid-template-columns: repeat(auto-fill, minmax(422px, 1fr));
    justify-items: center;
    justify-content: center;
}

.mobile-training-background {
    padding-bottom: 5%;
    padding-top: 20px;
    background: linear-gradient(135deg, white 60%, #2F3C52 40%);
    min-height: 100vh;
    overflow-y: auto;
}

.training-background {
    padding-bottom: 5%;
    padding-top: 45px;
    margin-left: 225px;
    background: linear-gradient(135deg, white 60%, #2F3C52 40%);
    min-height: 100vh;
    overflow-y: auto;
}

.visibility-icon {
    color: #0033a0;
    width: 13px !important;
    height: 13px !important;
}

.color-picker-wrapper {
    height: 13px;
    width: 13px;
    border: 1px solid grey;
}
.color-picker-inner-wrapper {
    position: absolute;
    z-index: 2;
    right: 5px;
}
.color-picker {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
.color-white {
    color: #FFFFFF !important;
}

.border-left {
    border-left: 1px solid #ebeef0;
}
.border-right {
    border-right: 1px solid #ebeef0;
}
.height-100p{
    height: 100% !important;
}
.min-height-40{
    min-height: 40px !important;
}
.min-height-110 {
    min-height: 110px !important;
}
.min-height-100p {
    min-height: 100%;
}
.min-height-100p-sub-10 {
    min-height: calc(100% - 10px);
}
.min-width-200 {
    min-width: 200px !important;
}
.min-height-300 {
    min-height: 300px !important;
}
.overflow-auto {
    overflow: auto;
}
.overflow-visible {
    overflow: visible;
}
.overflow-scroll {
    overflow: scroll;
}

.height-245 {
    height: 245px !important;
}
.height-272 {
    height: 272px !important;
}
.max-height-330 {
    max-height: 330px !important;
}

.width-25 {
    width: 25px !important;
}
.width-140 {
    width: 140px !important;
}
.width-150 {
    width: 150px !important;
}
.width-160 {
    width: 160px !important;
}
.width-175 {
    width: 175px !important;
}
.width-250 {
    width: 250px !important;
}
.width-100p {
    width: 100%;
}

.margin-0 {
    margin: 0 !important;
}
.margin-0-auto {
    margin: 0 auto !important;
}
.margin-0-20 {
    margin: 0 20px !important;
}
.margin-5 {
    margin: 5px !important;
}
.margin-5-0 {
    margin: 5px 0 !important;
}
.margin-8-0 {
    margin: 8px 0 !important;
}
.margin-5-10 {
    margin: 5px 10px !important;
}
.margin-20-30 {
    margin: 20px 30px !important;
}
.margin-left-0 {
    margin-left: 0 !important;
}
.margin-left-5 {
    margin-left: 5px !important;
}
.margin-left-10 {
    margin-left: 10px !important;
}
.margin-left-15 {
    margin-left: 15px !important;
}
.margin-left-80 {
    margin-left: 80px !important;
}
.margin-right-15 {
    margin-right: 15px !important;
}
.margin-right-25 {
    margin-right: 25px !important;
}
.margin-right-35 {
    margin-right: 35px !important;
}
.margin-top-5 {
    margin-top: 5px !important;
}
.margin-top-10 {
    margin-top: 10px !important;
}
.margin-top-20 {
    margin-top: 20px !important;
}
.margin-top-25 {
    margin-top: 25px !important;
}
.margin-top-50 {
    margin-top: 50px !important;
}
.margin-bottom-0 {
    margin-bottom: 0 !important;
}
.margin-bottom-2 {
    margin-bottom: 2px !important;
}
.margin-bottom-5 {
    margin-bottom: 5px !important;
}
.margin-bottom-10 {
    margin-bottom: 10px !important;
}
.margin-bottom-15 {
    margin-bottom: 15px !important;
}
.margin-bottom-20 {
    margin-bottom: 20px !important;
}
.pad-unset {
    padding: unset !important;
}
.pad-0 {
    padding: 0 !important;
}
.pad-5 {
    padding: 5px !important;
}
.pad-30 {
    padding: 30px !important;
}
.pad-0-10 {
    padding: 0 10px !important;
}
.pad-8-3 {
    padding: 8px 3px !important;
}
.pad-10-0 {
    padding: 10px 0 !important;
}
.pad-25-0-0-30 {
    padding: 25px 0 0 30px !important;
}
.pad-right-0 {
    padding-right: 0 !important;
}
.pad-right-3 {
    padding-right: 3px !important;
}
.pad-right-10 {
    padding-right: 10px !important;
}
.pad-right-30 {
    padding-right: 30px !important;
}
.pad-left-0 {
    padding-left: 0 !important;
}
.pad-left-10 {
    padding-left: 10px !important;
}
.pad-left-15 {
    padding-left: 15px !important;
}
.pad-left-30 {
    padding-left: 30px !important;
}
.pad-left-40 {
    padding-left: 40px !important;
}
.pad-left-45 {
    padding-left: 45px !important;
}
.pad-top-0 {
    padding-top: 0 !important;
}
.pad-top-5 {
    padding-top: 5px !important;
}
.pad-top-8 {
    padding-top: 8px !important;
}
.pad-top-15 {
    padding-top: 15px !important;
}
.pad-top-20 {
    padding-top: 20px !important;
}
.pad-bottom-5 {
    padding-bottom: 5px !important;
}
.pad-bottom-8 {
    padding-bottom: 8px !important;
}
.pad-bottom-10 {
    padding-bottom: 10px !important;
}
.pad-bottom-15 {
    padding-bottom: 15px !important;
}

.color-green {
    color: green;
}
.color-red {
    color: red;
}
.color-cornflowerblue {
    color: cornflowerblue;
}

.text-center {
    text-align: center;
}
.text-right {
    text-align: right;
}
.text-left {
    text-align: left;
}

.display-flex {
    display: flex;
}
.display-none {
    display: none;
}

.wifi-icon {
    height: 2em;
    width: 2.5em;
}

.address-title{
    background-color:#153755;
    padding:8px;
    color:#fff;
    font-weight:300;
     border-bottom: 1px solid #ebeef0;
}
.address-field{
    margin: 5px;
    padding-left:10px;
}
.address-btn{
    margin:5px;
    padding-left:10px;
    float: right;
}
.address-grid{
    border: 1px solid black;
    padding: 10px;
    margin: 5px;
    backgroundColor: white;
    width: 50%;
    height: 250px;
}
.address-container{
   width: 100%;
    display: flex;
}
@media screen and (max-width: 1475px) {
    .reactive-dashboard {
        display: inline;
    }
}